import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Content from '../atoms/Content'
import Newsletter from '../components/Newsletter'
import styled from 'styled-components'

const reasons = [
  `You want to tell me how bad you think the Knicks or Mets are. I already know.`,
  `You think Godfather 3 was a good movie. It wasn't.`,
  `You think lasagna is gross. Who are you trying to kid?`,
]

const Subheader = styled.h2`
  margin-bottom: 10px;
`

class Contact extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Contact"
          keywords={[`blog`, `python`, `javascript`, `react`]}
        />
        <Content marginBottom={20}>
          {/* <h1>Contact Me</h1> */}
          <Subheader>You should contact me if:</Subheader>
          <ul>
            <li>
              You want to hire me for a project (check out my services and
              clients <Link to="work">here</Link>)
            </li>
            <li>
              You've got a question about web development or consulting in
              general
            </li>
            <li>
              There's a subject you'd like me to write an article or record a
              video about
            </li>
            <li>You've got a recipe (of any cuisine!) you want to share</li>
          </ul>
          <Subheader>You should not contact me if:</Subheader>
          <ul>
            <li>{reasons[Math.floor(Math.random() * reasons.length)]}</li>
          </ul>
          <p css={{ 'margin-top': '40px' }}>
            Cool, you made it this far. Shoot me an email at{' '}
            <a href="mailto:steve@stevemerc.com">steve@stevemerc.com</a>, find
            me on <a href="https://twitter.com/mercatante">Twitter</a> and{' '}
            <a href="https://www.linkedin.com/in/stevenmercatante/">LinkedIn</a>
            , or sign up for my newsletter below if you want to be notified when
            I've published something new.
          </p>
        </Content>
        <Newsletter referrer="contact" marginTop={40} />
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
